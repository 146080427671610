import '@datawhale/wst-render/dist/style.css';
import 'element-plus/dist/index.css';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { ViteSSG } from 'vite-ssg';
import { routes } from './routers';
import './styles/global.css';
import './styles/reset.css';
import './styles/scrollbar.css';

import App from './App.vue';

export const createApp: any = ViteSSG(
  // the root component
  App,
  {
    routes,
    base: import.meta.env.BASE_URL,
  },
  // function to have custom setups
  ({ app, initialState, isClient, onSSRAppRendered }) => {
    const store = createPinia();
    store.use(piniaPluginPersistedstate);
    app.use(store);

    if (isClient) {
      store.state.value = initialState.pinia || {};
    } else {
      onSSRAppRendered(() => {
        initialState.pinia = store.state.value;
      });
    }
  },
);
